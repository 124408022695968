<template>
  <div>
    <v-btn class="ml-4" depressed @click.prevent="OpenApproval">
      Approval
    </v-btn>
    <v-dialog
      v-model="dialog.show"
      persistent
      scrollable
      max-width="450"
    >
      <v-card class="px-4">
        <v-card-title class="flex-column body-1 align-center text-uppercase font-weight-bold">
          <span class="subtitle-2">- APPROVAL -</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4 pr-2 pl-12" style="max-height: 90vh;">
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialog.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: {
      show: false,
      data: null
    }
  }),
  methods: {
    OpenApproval () {
      this.dialog.show = true
    }
  }
}
</script>
